<template>
   <component :is="as" :class="{ 'd-inline': inline }" v-bind="attrs" v-on="$listeners"><slot /></component>
</template>

<script>
import { keys, omit } from 'lodash';

export default {
   name: 'ObiText',
   props: {
      as: {
         type: [String, Object],
         default: 'div',
      },
      inline: {
         type: Boolean,
         default: false,
      },
   },
   computed: {
      attrs() {
         return omit(this.$attrs, keys(this.$props));
      },
   },
};
</script>

<style lang="scss" scoped>
.obi-text {
}
</style>
